
/**
 * gsap, decommentare per includere la libreria
 */
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;

/**
 * Jquery
 */
import jQuery from "jquery";
Object.assign(window, { $: jQuery, jQuery })


/**
 * Lenis
*/
import Lenis from 'lenis'
window.Lenis = Lenis; 

/**
 * Lazysizes
 */
import lazySizes from 'lazysizes';
lazySizes.cfg.init = false; // init manuale, v. sotto
lazySizes.cfg.loadMode = 0; // 1 = only load visible elements
window.lazySizes = lazySizes;

/**
 * Custom uikit build
 * Decommentare le righe per includere i componenti necessari
 */
import UIkit from 'uikit/dist/js/uikit-core';
// import Countdown from 'uikit/dist/js/components/countdown'; UIkit.component('Countdown', Countdown);
// import Filter from 'uikit/dist/js/components/filter';  UIkit.component('Filter', Filter);
// import Lightbox from 'uikit/dist/js/components/lightbox'; UIkit.component('Lightbox', Lightbox);   
// import LightboxPanel from 'uikit/dist/js/components/lightbox-panel'; UIkit.component('LightboxPanel', LightboxPanel); 
// import Notification from 'uikit/dist/js/components/notification'; UIkit.component('Notification', Notification);
//import Parallax from 'uikit/dist/js/components/parallax'; UIkit.component('Parallax', Parallax);
import Slider from 'uikit/dist/js/components/slider';  UIkit.component('Slider', Slider);
// import SliderParallax from 'uikit/dist/js/components/slider-parallax';  UIkit.component('SliderParallax', SliderParallax);
import Slideshow from 'uikit/dist/js/components/slideshow'; UIkit.component('Slideshow', Slideshow);
//import SlideshowParallax from 'uikit/dist/js/components/slideshow-parallax'; UIkit.component('SlideshowParallax', SlideshowParallax);
// import Sortable from 'uikit/dist/js/components/sortable'; UIkit.component('Sortable', Sortable);
import Tooltip from 'uikit/dist/js/components/tooltip'; UIkit.component('Tooltip', Tooltip);
// import Upload from 'uikit/dist/js/components/upload'; UIkit.component('Upload', Upload);
// import Icons from 'uikit/dist/js/uikit-icons'; UIkit.use(Icons);
//UIkit.use(Icons); 
window.UIkit = UIkit;



